<template>
<div>
    <div class="row">
          <div class="col">
            <table class="table table-hover" v-if="jobs">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">id</th>
                  <th scope="col">email</th>
                  <th scope="col">content</th>
                  <th scope="col">date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(client, index) in jobs" :key="client.id">
                  <th scope="row"> {{ index+1 }}</th>
                  <td>{{ client.id }}</td>
                  <td>{{ client.email }}</td>
                  <td>{{ client.content }}</td>
                  <td>{{ client.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'vjobs',
    data() {
        return {
            jobs: '',
            name: '',
            redirect: ''
        }
    },
    created() {
        this.getjobs();
    },
    methods: {
        getjobs() {
            const url = this.appDomainApi1 + "admin/contact/data";
            console.log(url);
            axios.get(url, )
                .then(res => {
                    this.jobs = res.data
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })
        },
    },
}
</script>
