<template>
  <div>
    <TitleAdmin msg="Contact" routerAdmin="Admin" />
    <Contacts />
  </div>
</template>

<script>
import Contacts from "@/components/admin/contact/index.vue";
import TitleAdmin from "@/views/constants/titleAdmin.vue";

export default {
  name: "vContacts",
  components: {
    Contacts,
    TitleAdmin,
  },
  created() {},
};
</script>
